import { HttpClient } from '@wix/http-client';

import {
  baseURL,
  metaSiteId,
  siteId,
  feedbackId,
  pMetaSiteId,
  editorType,
  siteName,
  feedbackCreatorUserGuid,
  ownerId,
  isOwner,
} from '../fromWindow';

const httpClient = new HttpClient({ baseURL });

export const SEND_SHARED_LINK = `/sendShare?metaSiteId=${metaSiteId}&siteId=${siteId}`;
export const GET_CONTACTS = `/share/listContacts?metaSiteId=${metaSiteId}`;
export const ADD_COMMENT = `/feedback/addComment`;
export const UPDATE_COMMENT = `/feedback/updateComment`;
export const DELETE_COMMENT = `/feedback/deleteComment`;
export const REPLY_TO_COMMENT = `/feedback/replyToComment`;
export const DELETE_REPLY_TO_COMMENT = `/feedback/deleteReplyToComment`;
export const UPDATE_REPLY_TO_COMMENT = `/feedback/updateReplyToComment`;
export const CREATE_CONTACT = `/feedback/createContact`;
export const SV_SESSION_GENERATOR = `/feedback/svSessionGenerator`;
export const SV_SESSION_CHECK = `/feedback/checkSvSession`;

export const sendSharedLink = async payload => {
  const {
    data: { status },
  } = await httpClient.post(`${SEND_SHARED_LINK}`, {
    payload,
  });
  return status.length;
};

export const getContacts = async () => {
  try {
    const { data } = await httpClient.get(`${GET_CONTACTS}`);
    return data;
  } catch (e) {}
};

export const addComment = async ({
  message,
  commentLocation,
  status,
  shouldSchedule,
}) => {
  const { data } = await httpClient.post(`${ADD_COMMENT}`, {
    feedbackId,
    metaSiteId,
    pMetaSiteId,
    siteId,
    editorType,
    siteName,
    ownerId,
    feedbackCreatorUserGuid,
    ...(shouldSchedule && { shouldSchedule }),
    comment: {
      message,
      commentLocation,
      status,
    },
  });
  return data.comment;
};

export const deleteComment = async commentId => {
  const { data } = await httpClient.post(`${DELETE_COMMENT}`, {
    feedbackId,
    metaSiteId,
    commentId,
  });
  return data;
};

export const updateComment = async ({
  id,
  feedbackId,
  message,
  commentLocation,
  commenterId,
  status,
  revision,
  fields,
}) => {
  const { data } = await httpClient.post(`${UPDATE_COMMENT}`, {
    feedbackId,
    commentId: id,
    metaSiteId,
    commenterId,
    comment: {
      message,
      commentLocation,
      status,
      revision,
    },
    fields,
  });
  return data.comment;
};

export const replyToComment = async ({
  id,
  feedbackId,
  revision,
  replyText,
  userInfo,
  commenterId,
}) => {
  const { data } = await httpClient.post(`${REPLY_TO_COMMENT}`, {
    feedbackId,
    commentId: id,
    metaSiteId,
    revision,
    isOwner,
    siteName,
    ownerId,
    feedbackCreatorUserGuid,
    editorType,
    siteId,
    commenterInfo: userInfo,
    commenterId,
    reply: {
      message: replyText,
    },
    pMetaSiteId,
  });

  return data.comment;
};

export const deleteReplyToComment = async ({
  commentId,
  feedbackId,
  revision,
  replyId,
  commenterId,
}) => {
  const { data } = await httpClient.post(`${DELETE_REPLY_TO_COMMENT}`, {
    feedbackId,
    commentId,
    metaSiteId,
    commenterId,
    revision,
    isOwner,
    pMetaSiteId,
    replyId,
  });
  return data.comment;
};

export const updateReplyToComment = async ({
  commentId,
  feedbackId,
  message,
  replyId,
  commenterId,
  revision,
}) => {
  const {
    data: { comment },
  } = await httpClient.post(`${UPDATE_REPLY_TO_COMMENT}`, {
    feedbackId,
    commentId,
    metaSiteId,
    message,
    commenterId,
    revision,
    replyId,
  });
  return comment;
};

export const sessionGenerator = async () => {
  const { data } = await httpClient.get(SV_SESSION_GENERATOR);
  return data;
};

export const checkSvSession = async () => {
  const { data } = await httpClient.get(SV_SESSION_CHECK);
  return data;
};

export const createContact = async ({ userName, userEmail }) => {
  const [first, last] = userName.split(' ');
  const { data } = await httpClient.post(`${CREATE_CONTACT}`, {
    first,
    last,
    email: userEmail,
    metaSiteId: pMetaSiteId || metaSiteId,
  });
  return data;
};
