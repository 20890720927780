import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Avatar, Text, TextButton, Divider } from 'wix-style-react';
import { timeDifferenceWithLocale } from '@wix/experts-common';
import {
  BreakpointsDesktop,
  BreakpointsTablet,
  BreakpointsMobileV,
} from '@wix/responsive-editor-symbols';
import { ResponsiveSiteContext } from '../App/ResponsiveSiteContext';
import {
  COMMENT_STATUS,
  VIEW_MODE,
  BREAKPOINT_MAX_VALUE,
} from '../../utils/constants';
import s from './CommentItem.scss';

const CommentItem = ({ comment, onOpenComment }) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const { isSiteResponsive } = useContext(ResponsiveSiteContext);
  const { breakpoint } = comment.commentLocation;

  const userName = comment.userInfo.userName || 'Anonymous';

  function renderBreakPointSVG(max, min) {
    if (max === BREAKPOINT_MAX_VALUE) {
      return (
        <>
          <BreakpointsDesktop />
          <Text
            className={s.range}
            weight="bold"
            size="tiny"
            secondary
            dataHook="breakpoint-desktop-max"
          >
            {`${min} ${t('get_feedback_px_and_up')}`}
          </Text>
        </>
      );
    } else if (max <= 758) {
      return (
        <>
          <BreakpointsMobileV />
          <Text
            weight="bold"
            size="tiny"
            secondary
            className={s.range}
            dataHook="breakpoint-mobile"
          >
            {min} - {max}
          </Text>
        </>
      );
    } else if (max < 1240) {
      return (
        <>
          <BreakpointsTablet />
          <Text
            weight="bold"
            size="tiny"
            secondary
            className={s.range}
            dataHook="breakpoint-tablet"
          >
            {min} - {max} {t('get_feedback_px')}
          </Text>
        </>
      );
    } else {
      return (
        <>
          <BreakpointsDesktop />
          <Text
            weight="bold"
            size="tiny"
            secondary
            className={s.range}
            dataHook="breakpoint-desktop"
          >
            {min} - {max} {t('get_feedback_px')}
          </Text>
        </>
      );
    }
  }

  function renderViewModeOrBreakPointIndication() {
    const { maxWidth, minWidth, id } = breakpoint;
    if (!isSiteResponsive) {
      if (id === VIEW_MODE.DESKTOP) {
        return (
          <Text
            light
            secondary
            size="tiny"
            skin="standard"
            weight="bold"
            dataHook="view-mode-tag"
          >
            {t('get_feedback_comments_list_filter_desktop')}
          </Text>
        );
      } else {
        return (
          <Text
            light
            secondary
            size="tiny"
            skin="standard"
            weight="bold"
            dataHook="view-mode-tag"
          >
            {t('get_feedback_comments_list_filter_mobile')}
          </Text>
        );
      }
    } else {
      if (comment.isMigrated) {
        return renderBreakPointSVG(BREAKPOINT_MAX_VALUE, minWidth);
      }
      return renderBreakPointSVG(maxWidth, minWidth);
    }
  }

  return (
    <Card className={s.root} dataHook="comment-item">
      <div
        className={`${s.content} ${
          comment.status === COMMENT_STATUS.UNREAD ? s.newStatus : ''
        }`}
      >
        <div className={s.header}>
          <Avatar name={userName} color={comment.color} size="size30" />
          <Text
            weight="bold"
            size="small"
            secondary
            className={s.name}
            dataHook="comment-submitter"
          >
            {userName}
          </Text>
          <Text
            light
            secondary
            size="tiny"
            skin="standard"
            weight={comment.status === COMMENT_STATUS.UNREAD ? 'bold' : 'thin'}
            dataHook="comment-time-since"
          >
            {timeDifferenceWithLocale({
              timeStamp: comment.createdDate,
              locale,
              justNowText: t('get_feedback_now'),
            })}
          </Text>
        </div>
        <Text
          className={s.lineClamp}
          size="small"
          skin="standard"
          weight="thin"
          tagName="div"
          dataHook="comment-text"
        >
          {comment.message}
        </Text>
        <div className={s.footer}>
          <div className={s.footerItem}>
            {renderViewModeOrBreakPointIndication()}
          </div>
          <TextButton
            className={s.footerItem}
            size="tiny"
            dataHook="comment-cta"
            onClick={e => {
              e.stopPropagation();
              onOpenComment(comment);
            }}
          >
            {t('get_feedback_comments_list_comment_cta')}
          </TextButton>
        </div>
      </div>
      <Divider />
    </Card>
  );
};

export default CommentItem;
