/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import uuid from 'uuid/v4';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import {
  BreakpointsDesktop,
  BreakpointsTablet,
  BreakpointsMobileV,
} from '@wix/responsive-editor-symbols';
import {
  DropdownBase,
  TextButton,
  listItemActionBuilder,
  Text,
} from 'wix-style-react';
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import { BreakpointsContext } from '../App/BreakpointsContext';
import { DocumentServicesContext } from '../App/DocumentServicesContext';
import { ClientContext } from '../App/ClientContext';
import FeedbackContext from '../App/FeedbackContext';
import {
  BREAKPOINT_MAX_VALUE,
  BREAKPOINT_MAX_ID,
  TPA_DESKTOP_WIDTH_THRESHOLD,
} from '../../utils/constants';
import * as documentAPIs from '../../utils/documentAPI';
import s from './Breakpoints.scss';
import logger from '../../utils/biLogger';
import {
  getFeedbackChooseMode,
  getFeedbackClickOnHeader,
} from '@wix/bi-logger-get-feedback/v2';

function Breakpoints({ breakpoints }) {
  const { t } = useTranslation();

  const { setBreakPoint, currentBreakPoint } = useContext(BreakpointsContext);
  const { documentServices } = useContext(DocumentServicesContext);
  const { editorType, role } = useContext(FeedbackContext);
  const {
    user: { contactId },
  } = useContext(ClientContext);

  function breakpointsRange() {
    const sorted = _sortBy(breakpoints.values, bp => bp.max || Infinity);
    const documentBreakpoints = sorted
      .map((bp, index) => {
        if (index !== 0) {
          const prevMax = sorted[index - 1].max;
          return { ...bp, min: prevMax + 1 };
        }
        return bp;
      })
      .reverse();
    documentBreakpoints.unshift({
      min: _get(documentBreakpoints[0], 'max', 0) + 1,
      max: BREAKPOINT_MAX_VALUE,
      id: BREAKPOINT_MAX_ID,
    });
    return documentBreakpoints;
  }

  useEffect(() => {
    setBreakPoint(breakpointsRange()[0]);
  }, []);

  function isSelected(id) {
    return currentBreakPoint.id === id;
  }

  function renderBreakPointSVG(max, min, id) {
    if (max === BREAKPOINT_MAX_VALUE) {
      return (
        <BreakpointsDesktop
          className={isSelected(BREAKPOINT_MAX_ID) ? s.selected : ''}
        />
      );
    } else if (max <= 758) {
      return (
        <BreakpointsMobileV className={isSelected(id) ? s.selected : ''} />
      );
    } else if (max < 1240) {
      return <BreakpointsTablet className={isSelected(id) ? s.selected : ''} />;
    } else {
      return (
        <BreakpointsDesktop className={isSelected(id) ? s.selected : ''} />
      );
    }
  }

  function renderBreakPointTitle(max, min) {
    if (max === BREAKPOINT_MAX_VALUE) {
      return t('get_feedback_header_breakpoints_desktop');
    } else if (max <= 758) {
      return t('get_feedback_header_breakpoints_mobile');
    } else if (max < 1240) {
      return t('get_feedback_header_breakpoints_tablet');
    } else {
      return t('get_feedback_header_breakpoints_desktop');
    }
  }

  function getBreakPointsOptions() {
    return breakpointsRange().map(({ max, min, id }) => {
      const breakpointTitle = renderBreakPointTitle(max, min);
      return listItemActionBuilder({
        id,
        skin: 'dark',
        value: breakpointTitle,
        prefixIcon: renderBreakPointSVG(max, min, id),
        onClick: () => {
          logger.report(
            getFeedbackChooseMode({
              user_type: role,
              editorVersion: editorType,
              mode: breakpointTitle,
              breakPoint: JSON.stringify({ max, min, id }),
              ...(contactId && { contactId }),
              ...(contactId
                ? { _client_id: contactId }
                : { _client_id: uuid() }),
            }),
          );
          setBreakPoint({ max, min, id });
          const isDesktop =
            max > TPA_DESKTOP_WIDTH_THRESHOLD || max === BREAKPOINT_MAX_VALUE;
          documentServices.tpa.setDeviceType(isDesktop ? 'desktop' : 'mobile');
        },
        title: renderBreakPointTitle(max, min),
      });
    });
  }

  function getSelectedBreakPointSVG() {
    const { max, min, id } = currentBreakPoint;
    return renderBreakPointSVG(max, min, id);
  }

  function getSelectedBreakPointTitle() {
    const { max, min } = currentBreakPoint;
    return renderBreakPointTitle(max, min);
  }

  return (
    <>
      <ul
        className={s.breakpointsPreviewBar}
        data-hook="breakpoint-preview-bar"
      >
        <li>
          <div className={s.divider}></div>
        </li>
        <li style={{ width: '131px' }}>
          <DropdownBase
            data-hook="story-dropdown-base-uncontrolled-click"
            options={getBreakPointsOptions()}
          >
            {({ toggle }) => {
              return (
                <>
                  <TextButton
                    size="small"
                    skin="dark"
                    suffixIcon={<ChevronDown />}
                    prefixIcon={getSelectedBreakPointSVG()}
                    onClick={() => {
                      logger.report(
                        getFeedbackClickOnHeader({
                          evid: 1131,
                          user_type: role,
                          editorVersion: editorType,
                          ...(contactId && { contactId }),
                          ...(contactId
                            ? { _client_id: contactId }
                            : { _client_id: uuid() }),
                        }),
                      );
                      toggle();
                    }}
                    dataHook="drop-down-opener"
                  >
                    {getSelectedBreakPointTitle()}
                  </TextButton>
                </>
              );
            }}
          </DropdownBase>
        </li>
        <li>
          <div className={s.divider}></div>
        </li>
        <li>
          <Text size="small" weight="thin">
            W
          </Text>
        </li>
        <li style={{ width: '117px' }}>
          <Text size="small" weight="thin" className={s.widthTitle}>
            {currentBreakPoint.max === BREAKPOINT_MAX_VALUE
              ? window.innerWidth
              : documentAPIs.calcBreakpointWidth({ ...currentBreakPoint })}
            <span style={{ paddingTop: '4px' }}>px</span>
          </Text>
        </li>
        <li>
          <div className={s.divider}></div>
        </li>
      </ul>
    </>
  );
}

export default Breakpoints;
