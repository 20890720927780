import React from 'react';
import { IconButton } from 'wix-style-react';
import X from 'wix-ui-icons-common/X';
import { OWNER_HELP_LINK, CLIENT_HELP_LINK } from '../../utils/constants';
import s from './HelpBox.scss';

const HelpBox = ({ shouldShowHelpBox, isOwner, onClose }) =>
  shouldShowHelpBox && (
    <div className={s.helpBoxWrapper}>
      <IconButton
        className={s.closeButton}
        onClick={onClose}
        priority="secondary"
        skin="transparent"
        size="tiny"
      >
        <X />
      </IconButton>
      <iframe
        data-hook="help-box-iframe"
        src={isOwner ? OWNER_HELP_LINK : CLIENT_HELP_LINK}
      />
    </div>
  );

export default HelpBox;
