import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Button,
  Layout,
  Cell,
  Text,
  CloseButton,
  TextButton,
  Box,
  Popover,
} from 'wix-style-react';
import InfoCircle from 'wix-ui-icons-common/InfoCircle';
import { CLIENT_ADD_COMMENT_HELP_LINK } from '../../../utils/constants';
import style from './PopoverHelp.scss';

const PopoverHelp = () => {
  const { t, i18n } = useTranslation();
  const [shown, setShown] = useState(false);
  const locale = i18n.language;

  return (
    <Popover
      showArrow
      animate
      placement="bottom"
      shown={shown}
      onClickOutside={() => setShown(false)}
      appendTo="window"
    >
      <Popover.Element>
        <Box align="center">
          <Box marginRight="tiny">
            <TextButton
              style={{ color: '#3899ec' }}
              prefixIcon={<InfoCircle />}
              dataHook="client-add-comments-help"
              skin="dark"
              onClick={() => setShown(true)}
            >
              {t('get_feedback_header_user_help_button')}
            </TextButton>
          </Box>
        </Box>
      </Popover.Element>
      <Popover.Content dataHook="client-add-comments-help-popover-content">
        <div className={style.container} data-hook="welcome-screen">
          <Card
            controls={
              <CloseButton
                dataHook="welcome-screen-close-button"
                size="medium"
                skin="dark"
                onClick={() => setShown(false)}
              />
            }
          >
            <Box padding="36px 36px 30px">
              <Layout cols={1} gap={6}>
                <Cell>
                  <Text weight="bold" skin="standard">
                    {t('get_feedback_user_help_title')}
                  </Text>
                </Cell>
                <Cell>
                  <Text skin="standard">
                    {t('get_feedback_user_help_description')}
                  </Text>
                </Cell>
                <Cell>
                  <div style={{ paddingTop: '10px' }}>
                    <Button
                      as="a"
                      href={CLIENT_ADD_COMMENT_HELP_LINK(locale)}
                      target="_blank"
                      priority="secondary"
                      size="large"
                      onClick={() => setShown(false)}
                      dataHook="welcome-screen-got-it"
                    >
                      {t('get_feedback_user_help_cta')}
                    </Button>
                  </div>
                </Cell>
              </Layout>
            </Box>
          </Card>
        </div>
      </Popover.Content>
    </Popover>
  );
};

export default PopoverHelp;
