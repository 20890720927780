/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useReducer } from 'react';
import uuid from 'uuid/v4';
import _size from 'lodash/size';
import _find from 'lodash/find';
import _isUndefined from 'lodash/isUndefined';
import { useTranslation } from 'react-i18next';
import { FloatingNotification, WixStyleReactProvider } from 'wix-style-react';
import StatusAlert from 'wix-ui-icons-common/StatusAlert';
import { assignColorToComments } from '../../utils/colorComments';
import * as Sentry from '@sentry/browser';
import {
  updateComment,
  sessionGenerator,
  checkSvSession,
} from '../../utils/api';
import {
  VIEW_MODE,
  DOCUMENT_HEADER,
  COMMENT_STATUS,
  EDITOR_TYPE,
} from '../../utils/constants';
import { scrollTo } from '../SiteRenderer/frameHelpers';
import { user as userFromWindow } from '../../fromWindow';
import SiteRenderProps from './SiteRendererProps';
import CommentsReducer from './reducers/Comments.reducer';
import FeedbackContext from './FeedbackContext';
import { DocumentServicesContext } from './DocumentServicesContext';
import { ResponsiveSiteContext } from './ResponsiveSiteContext';
import { ClientContext } from './ClientContext';
import { ViewModeContext } from './ViewModeContext';
import { BreakpointsContext } from './BreakpointsContext';
import HelpBox from '../HelpBox/HelpBox';
import SiteRenderer from '../SiteRenderer/SiteRenderer';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import WelcomeScreen from '../WelcomeScreen/WelcomeScreen';
import Header from '../Header/Header';
import DisableTool from '../DisableTool';
import style from './App.scss';
import logger from '../../utils/biLogger';
import {
  getFeedbackViewCommentsPageLoaded,
  getFeedbackClientSidePageLoaded,
} from '@wix/bi-logger-get-feedback/v2';

function App({ mockDocumentServices }) {
  const {
    isOwner,
    editorType,
    savedComments,
    commentId,
    role,
    error: disableTool,
  } = useContext(FeedbackContext);
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState(VIEW_MODE.DESKTOP);
  const [currentBreakPoint, setBreakPoint] = useState({});
  const [showWelcome, setShowWelcome] = useState(false);
  const [shouldShowHelpBox, setShouldShowHelpBox] = useState(false);
  const [documentServices, setDocumentServices] = useState(null);
  const [isSiteResponsive, setSiteIsResponsive] = useState(
    editorType === EDITOR_TYPE.EDITORX,
  );
  const [user, setUser] = useState(userFromWindow || {});
  const [pageId, setPageId] = useState(null);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(true);
  const [isLoadingSite, setIsLoadingSite] = useState(true);
  const [showError, setShowError] = useState(false);
  const [resolvedFilterOn, setResolvedFilterOn] = useState(false);
  const [openFilterOn, setOpenFilterOn] = useState(true);

  const documentServicesProviderValue = {
    documentServices,
    setDocumentServices,
  };
  const responsiveSiteProviderValue = { isSiteResponsive, setSiteIsResponsive };
  const clientProviderValue = { user, setUser };
  const viewModeProviderValue = { viewMode, setViewMode };
  const breakpointsProviderValue = { currentBreakPoint, setBreakPoint };

  const commentsReducer = new CommentsReducer({
    viewMode,
    currentBreakPoint,
    documentServices,
    pageId,
    isSiteResponsive,
  });
  const [comments, dispatch] = useReducer(
    commentsReducer.reducer.bind(commentsReducer),
    [],
  );

  const siteRenderProps = new SiteRenderProps(
    dispatch,
    setShowError,
    editorType,
    setUser,
  );

  if (documentServices) {
    if (pageId !== documentServices.pages.getCurrentPageId()) {
      setPageId(documentServices.pages.getCurrentPageId());
    }
  }

  useEffect(() => {
    if (documentServices) {
      setIsLoadingSite(false);
      setShowError(false);
      documentServices.registerToSiteChanged(() => {
        const currentPageId = documentServices.pages.getCurrentPageId();
        if (currentPageId !== pageId) {
          setPageId(currentPageId);
        }
      });
    }
  }, [documentServices, pageId]);

  useEffect(() => {
    if (commentId) {
      const comment = _find(savedComments, ({ id }) => {
        return id === commentId;
      });
      if (comment && documentServices) {
        handleOpenComment(comment);
      }
    }
  }, [commentId, documentServices]);

  useEffect(() => {
    if (!showError && documentServices) {
      if (isOwner) {
        logger.report(
          getFeedbackViewCommentsPageLoaded({
            editorVersion: editorType,
            user_type: role,
          }),
        );
      } else {
        const contactId = user.contactId;
        logger.report(
          getFeedbackClientSidePageLoaded({
            editorVersion: editorType,
            ...(contactId && { contactId }),
            ...(contactId ? { _client_id: contactId } : { _client_id: uuid() }),
          }),
        );
      }
    }
  }, [documentServices, showError, isOwner, editorType]);

  useEffect(() => {
    async function generateAndValidateSession() {
      if (user.isAnonymous) {
        await sessionGenerator();
        try {
          const { svSession } = await checkSvSession();
          if (_isUndefined(svSession)) {
            window.parent.postMessage(
              'redirect',
              'https://www.getsitefeedback.com',
            );
          }
        } catch (e) {}
      }
    }
    generateAndValidateSession();
  }, [user.isAnonymous]);

  useEffect(() => {
    if (_size(savedComments) > 0) {
      dispatch({
        type: 'SET_COMMENTS',
        comments: isOwner
          ? assignColorToComments(savedComments)
          : savedComments,
      });
    }
    if (!isOwner && _size(savedComments) === 0) {
      setShowWelcome(true);
    }
  }, [isOwner]);

  useEffect(() => {
    if (!isOwner && _size(savedComments) === 0) {
      setShowWelcome(true);
    }
  }, [isOwner]);

  function handleSetFilterOpenComments(toggle) {
    setOpenFilterOn(toggle);
  }

  function handleSetFilterResolvedComments(toggle) {
    setResolvedFilterOn(toggle);
  }

  function changeViewModeAndOpenComment(id, selectedViewMode) {
    if (selectedViewMode !== viewMode) {
      setViewMode(selectedViewMode);
      setIsLoadingSite(true);
      documentServices.registerToSiteChanged(() => {
        if (documentServices.viewMode.get() === selectedViewMode) {
          dispatch({ type: 'OPEN_COMMENT', id });
          setIsLoadingSite(false);
        }
      });
    } else {
      dispatch({ type: 'OPEN_COMMENT', id });
    }
  }
  async function markCommentAsRead(comment) {
    if (comment.status === COMMENT_STATUS.UNREAD) {
      const { id } = comment;
      const changes = { status: COMMENT_STATUS.OPEN };
      const fields = ['status'];
      dispatch({ type: 'UPDATE_COMMENT', id, changes });
      try {
        const { revision } = await updateComment({
          ...comment,
          ...changes,
          fields,
        });
        dispatch({ type: 'UPDATE_COMMENT', id, changes: { revision } });
      } catch (e) {
        setShowError(true);
      }
    }
  }

  async function markCommentAsResolved(comment) {
    if (comment.status !== COMMENT_STATUS.RESOLVED) {
      const { id } = comment;
      const changes = { status: COMMENT_STATUS.RESOLVED };
      const fields = ['status'];
      dispatch({ type: 'UPDATE_COMMENT', id, changes });

      try {
        const { revision } = await updateComment({
          ...comment,
          ...changes,
          fields,
        });
        dispatch({ type: 'UPDATE_COMMENT', id, changes: { revision } });
      } catch (e) {
        setShowError(true);
      }
    }
  }

  function handleOpenComment(comment) {
    const { id, commentLocation } = comment;
    const { breakpoint } = commentLocation;
    dispatch({ type: 'DE_ACTIVATE_COMMENTS' });
    if (
      commentLocation.pageId !== pageId &&
      commentLocation.pageId !== DOCUMENT_HEADER
    ) {
      documentServices.pages.navigateTo(commentLocation.pageId);
    }
    if (isSiteResponsive) {
      const { maxWidth, minWidth } = breakpoint;
      if (!comment.isMigrated) {
        setBreakPoint({ max: maxWidth, min: minWidth, id: breakpoint.id });
      }
      dispatch({ type: 'OPEN_COMMENT', id });
    } else {
      changeViewModeAndOpenComment(id, breakpoint.id);
    }
    markCommentAsRead(comment);
    scrollTo(commentLocation.x, commentLocation.y);
  }

  return (
    <WixStyleReactProvider as="span">
      <DocumentServicesContext.Provider value={documentServicesProviderValue}>
        <ResponsiveSiteContext.Provider value={responsiveSiteProviderValue}>
          <ViewModeContext.Provider value={viewModeProviderValue}>
            <BreakpointsContext.Provider value={breakpointsProviderValue}>
              <ClientContext.Provider value={clientProviderValue}>
                <div className={style.root}>
                  {disableTool ? <DisableTool /> : <></>}
                  {!disableTool && isLoadingSite ? (
                    <LoadingScreen showLoader={showLoadingOverlay} />
                  ) : null}

                  <Header
                    comments={comments}
                    isLoadingSite={isLoadingSite}
                    closeAllComments={() => {
                      dispatch({ type: 'DE_ACTIVATE_COMMENTS' });
                    }}
                    onDesktopClick={() => setViewMode(VIEW_MODE.DESKTOP)}
                    onMobileClick={() => setViewMode(VIEW_MODE.MOBILE)}
                    onAddComment={() => {
                      dispatch({ type: 'DE_ACTIVATE_COMMENTS' });
                      dispatch({ type: 'ADD_COMMENT' });
                      setShowWelcome(false);
                    }}
                    onOpenComment={handleOpenComment}
                    onHelpButtonClick={() => setShouldShowHelpBox(true)}
                    onSetFilterOpenComments={toggle =>
                      handleSetFilterOpenComments(toggle)
                    }
                    onSetFilterResolvedComments={toggle =>
                      handleSetFilterResolvedComments(toggle)
                    }
                    resolvedFilterOn={resolvedFilterOn}
                    openFilterOn={openFilterOn}
                  />
                  {showWelcome ? (
                    <WelcomeScreen
                      dismissWelcome={() => {
                        setShowWelcome(false);
                      }}
                    ></WelcomeScreen>
                  ) : (
                    ''
                  )}
                  {showError ? (
                    <FloatingNotification
                      onClose={() => {
                        setShowError(false);
                      }}
                      className={style.errorNotification}
                      type="warning"
                      showTextButton
                      textButtonProps={{
                        label: t('get_feedback_error_cta'),
                        onClick: () => {
                          window.location.reload();
                        },
                      }}
                      prefixIcon={<StatusAlert />}
                      text={t('get_feedback_error')}
                      width="45%"
                    />
                  ) : (
                    ''
                  )}
                  <SiteRenderer
                    style={style.root}
                    mockDocumentServices={mockDocumentServices}
                    comments={comments}
                    pageId={pageId}
                    siteDidNotRender={() => {
                      Sentry.captureException(
                        `failed to load feedback-ng site renderer`,
                      );
                      setShowLoadingOverlay(false);
                      if (!showError) {
                        setShowError(true);
                      }
                    }}
                    resolvedFilterOn={resolvedFilterOn}
                    openFilterOn={openFilterOn}
                    onMarkCommentAsRead={comment => markCommentAsRead(comment)}
                    onUpdateForStaleComment={(comment, changes) =>
                      siteRenderProps.onUpdateForStaleComment(comment, changes)
                    }
                    closeAllComments={options =>
                      siteRenderProps.closeAllComments(options)
                    }
                    onDeleteComment={(comment, user) =>
                      siteRenderProps.onDeleteComment(comment, user)
                    }
                    onUpdateComment={(comment, changes) =>
                      siteRenderProps.onUpdateComment(comment, changes)
                    }
                    onResolveComment={comment => markCommentAsResolved(comment)}
                    onAppendComment={(comment, changes, user) =>
                      siteRenderProps.onAppendComment(comment, changes, user)
                    }
                    onAppendOwnerReply={(comment, changes) =>
                      siteRenderProps.onAppendOwnerReply(comment, changes)
                    }
                    onAppendSubmitterReply={(comment, changes, user) =>
                      siteRenderProps.onAppendSubmitterReply(
                        comment,
                        changes,
                        user,
                      )
                    }
                    onDeleteReplyToComment={(comment, reply) => {
                      siteRenderProps.onDeleteReplyToComment(comment, reply);
                    }}
                    onUpdateReplyToComment={(comment, reply, changes, user) => {
                      siteRenderProps.onUpdateReplyToComment(
                        comment,
                        reply,
                        changes,
                        user,
                      );
                    }}
                    onCreateContactAndAddComment={(user, comment, changes) =>
                      siteRenderProps.onCreateContactAndAddComment(
                        user,
                        comment,
                        changes,
                      )
                    }
                  />
                  <HelpBox
                    shouldShowHelpBox={shouldShowHelpBox}
                    isOwner={isOwner}
                    onClose={() => setShouldShowHelpBox(false)}
                  />
                </div>
              </ClientContext.Provider>
            </BreakpointsContext.Provider>
          </ViewModeContext.Provider>
        </ResponsiveSiteContext.Provider>
      </DocumentServicesContext.Provider>
    </WixStyleReactProvider>
  );
}

export default App;
