import _size from 'lodash/size';
import _last from 'lodash/last';
import _filter from 'lodash/filter';
import _isMatch from 'lodash/isMatch';

import {
  addComment,
  deleteComment,
  updateComment,
  replyToComment,
  deleteReplyToComment,
  updateReplyToComment,
  createContact,
} from '../../utils/api';
import { COMMENT_STATUS_CLIENT } from '../../utils/constants';
import logger from '../../utils/biLogger';
import {
  getFeedbackDeleteCommentOrResponse,
  getFeedbackAddCommentOrReply,
  getFeedbackClickEditReply,
} from '@wix/bi-logger-get-feedback/v2';

const state = {
  count: 0,
};

function needsToUpdateServer(
  comment,
  { commentLocation: { x, y, pageId } = {}, message, status },
) {
  if (
    (_isMatch(comment.commentLocation, { x, y, pageId }) &&
      _isMatch(comment.status, status) &&
      _isMatch(comment.message, message)) ||
    comment.status === COMMENT_STATUS_CLIENT.NEW ||
    status === COMMENT_STATUS_CLIENT.EDIT
  ) {
    return false;
  }
  return x || y || message || pageId || status;
}

function shouldSchedule() {
  return state.count++ === 0;
}

export default class SiteRendererProps {
  constructor(dispatch, setShowError, editorType, setUser) {
    this.dispatch = dispatch;
    this.setShowError = setShowError;
    this.editorType = editorType;
    this.setUser = setUser;
  }

  closeAllComments({ removeStale = false } = {}) {
    this.setShowError(false);
    this.dispatch({ type: 'DE_ACTIVATE_COMMENTS', removeStale });
  }

  async onDeleteComment(
    { id, status, commentLocation: { pageId, breakpoint } },
    user,
  ) {
    this.dispatch({ type: 'DELETE_COMMENT', id });
    try {
      await deleteComment(id);
      logger.report(
        getFeedbackDeleteCommentOrResponse({
          origin: 'client-side-page',
          user_type: 'commenter',
          type: 'comment',
          editorVersion: this.editorType,
          comment_id: id,
          commentStatus: status,
          page_id: pageId,
          breakpoint: JSON.stringify(breakpoint),
          ...(user.contactId && { contactId: user.contactId }),
        }),
      );
    } catch (e) {
      this.setShowError(true);
    }
  }

  async onUpdateComment(comment, changes) {
    this.dispatch({ type: 'UPDATE_COMMENT', id: comment.id, changes });
    if (needsToUpdateServer(comment, changes)) {
      const changeKeys = Object.keys(changes);
      const fields = _filter(changeKeys, function(o) {
        return o !== 'isActive' && o !== 'keepPopupOpen';
      });
      try {
        const { revision } = await updateComment({
          ...comment,
          ...changes,
          fields,
        });
        this.dispatch({
          type: 'UPDATE_COMMENT',
          id: comment.id,
          changes: { revision },
        });
      } catch (e) {
        this.setShowError(true);
      }
    }
  }

  onUpdateForStaleComment(comment, changes) {
    this.dispatch({
      type: 'UPDATE_COMMENT',
      id: comment.id,
      changes,
    });
  }

  async onCreateContactAndAddComment(user, comment, changes) {
    try {
      const { contact } = await createContact(user);
      this.setUser({
        ...user,
        contactId: contact.contactId,
      });
      this.dispatch({ type: 'UPDATE_COMMENT', id: comment.id, changes });
      const {
        id,
        createdDate,
        feedbackId,
        revision,
        updatedDate,
        commentLocation: { pageId, breakpoint },
      } = await addComment({
        ...comment,
        ...changes,
        shouldSchedule: shouldSchedule(),
      });

      logger.report(
        getFeedbackAddCommentOrReply({
          origin: 'client-side-page',
          user_type: 'commenter',
          type: 'comment',
          comment_id: id,
          page_id: pageId,
          editorVersion: this.editorType,
          breakpoint: JSON.stringify(breakpoint),
          text: changes.message,
          contactId: contact.contactId,
          _client_id: contact.contactId,
        }),
      );

      this.dispatch({
        type: 'UPDATE_COMMENT',
        id: comment.id,
        changes: { id, createdDate, feedbackId, revision, updatedDate },
      });
    } catch (e) {
      this.setShowError(true);
    }
  }

  async onAppendComment(comment, changes, user) {
    this.dispatch({ type: 'UPDATE_COMMENT', id: comment.id, changes });
    try {
      const {
        id,
        createdDate,
        feedbackId,
        revision,
        updatedDate,
        commentLocation: { pageId, breakpoint },
      } = await addComment({
        ...comment,
        ...changes,
        shouldSchedule: shouldSchedule(),
      });

      logger.report(
        getFeedbackAddCommentOrReply({
          origin: 'client-side-page',
          user_type: 'commenter',
          type: 'comment',
          comment_id: id,
          page_id: pageId,
          editorVersion: this.editorType,
          breakpoint: JSON.stringify(breakpoint),
          text: changes.message,
          ...(user.contactId && { contactId: user.contactId }),
          ...(user.contactId && { _client_id: user.contactId }),
        }),
      );

      this.dispatch({
        type: 'UPDATE_COMMENT',
        id: comment.id,
        changes: { id, createdDate, feedbackId, revision, updatedDate },
      });
    } catch (e) {
      this.setShowError(true);
    }
  }

  async onAppendOwnerReply(comment, changes) {
    this.dispatch({ type: 'UPDATE_COMMENT', id: comment.id, changes });
    try {
      const serverComment = await replyToComment({ ...comment, ...changes });
      const {
        replies,
        revision,
        commentLocation: { pageId, breakpoint },
      } = serverComment;

      logger.report(
        getFeedbackAddCommentOrReply({
          origin: 'client-side-page',
          user_type: 'owner',
          type: 'reply',
          response_id: _last(replies).id,
          response_number: _size(replies) - 1,
          text: changes.replyText,
          page_id: pageId,
          comment_id: comment.id,
          editorVersion: this.editorType,
          breakpoint: JSON.stringify(breakpoint),
        }),
      );

      this.dispatch({
        type: 'UPDATE_COMMENT',
        id: comment.id,
        changes: { replies, revision },
      });
    } catch (e) {
      this.setShowError(true);
    }
  }

  async onAppendSubmitterReply(comment, changes, user) {
    this.dispatch({ type: 'UPDATE_COMMENT', id: comment.id, changes });
    try {
      const serverComment = await replyToComment({ ...comment, ...changes });
      const {
        replies,
        revision,
        commentLocation: { pageId, breakpoint },
      } = serverComment;

      logger.report(
        getFeedbackAddCommentOrReply({
          origin: 'client-side-page',
          user_type: 'commenter',
          type: 'reply',
          response_id: _last(replies).id,
          response_number: _size(replies) - 1,
          text: changes.replyText,
          page_id: pageId,
          comment_id: comment.id,
          editorVersion: this.editorType,
          breakpoint: JSON.stringify(breakpoint),
          commentStatus: comment.status,
          ...(user.contactId && { contactId: user.contactId }),
          ...(user.contactId && { _client_id: user.contactId }),
        }),
      );

      this.dispatch({
        type: 'UPDATE_COMMENT',
        id: comment.id,
        changes: { replies, revision },
      });

      await this.onUpdateComment(serverComment, {
        status: COMMENT_STATUS_CLIENT.POSTED,
      });
    } catch (e) {
      this.setShowError(true);
    }
  }

  async onDeleteReplyToComment(comment, reply) {
    const { feedbackId, revision } = comment;
    this.dispatch({
      type: 'DELETE_REPLY_TO_COMMENT',
      commentId: comment.id,
      replyId: reply.id,
    });
    try {
      const commentWithDeletedReply = await deleteReplyToComment({
        commentId: comment.id,
        feedbackId,
        commenterId: comment.commenterId,
        revision,
        replyId: reply.id,
      });
      this.dispatch({
        type: 'UPDATE_COMMENT',
        id: comment.id,
        changes: {
          revision: commentWithDeletedReply.revision,
          replies: commentWithDeletedReply.replies,
        },
      });
    } catch (e) {
      this.setShowError(true);
    }
  }

  async onUpdateReplyToComment(comment, reply, changes, user) {
    const { feedbackId, revision } = comment;
    const { message } = changes;
    this.dispatch({
      type: 'UPDATE_REPLY_TO_COMMENT',
      commentId: comment.id,
      replyId: reply.id,
      changes,
    });
    try {
      const updatedReplyComment = await updateReplyToComment({
        commentId: comment.id,
        feedbackId,
        revision,
        commenterId: comment.commenterId,
        replyId: reply.id,
        message,
      });

      logger.report(
        getFeedbackClickEditReply({
          origin: 'view-comment-page',
          comment_id: comment.id,
          editorVersion: this.editorType,
          breakpoint: JSON.stringify(
            updatedReplyComment.commentLocation.breakpoint,
          ),
          commentStatus: comment.status,
          ...(user.contactId && { contactId: user.contactId }),
        }),
      );

      this.dispatch({
        type: 'UPDATE_COMMENT',
        id: comment.id,
        changes: {
          revision: updatedReplyComment.revision,
        },
      });
    } catch (e) {
      this.setShowError(true);
    }
  }
}
