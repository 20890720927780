export const VIEW_MODE = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
};

export const VIEW_MODE_DOCUMENT_VALUES = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
};

export const ORIGINS = {
  ADI: 'OnBoarding',
  EDITOR_X: 'editor-x',
  EDITOR: 'editor',
  MY_ACCOUNT: 'MyAccount',
  GET_FEEDBACK_WIDGET: 'GetFeedbackWidget',
};

export const PRO_USER = 'pro';

export const PREVIEW_ID = 'previewFrame';

export const DOCUMENT_HEADER = 'SITE_HEADER';

export const DOCUMENT_CONTAINER = 'SITE_CONTAINER';

export const DOCUMENT_SERVICES_LOADED = 'documentServicesLoaded';

export const DOCUMENT_MASTER_PAGE = 'masterPage';

export const OWNER_HELP_LINK =
  'https://www.wix.com/support/html5/widget/0/cb6480f3-768b-4737-ab8f-10fe2e4fa2ab#/main/view?itemId=133dc257-7b42-4019-8b3b-0f25d23b6ae5';

export const CLIENT_HELP_LINK =
  'https://www.wix.com/support/html5/widget/0/cb6480f3-768b-4737-ab8f-10fe2e4fa2ab#/main/view?itemId=ee49dbc5-5430-46d4-a2d6-da00241e6095';

export const CLIENT_ADD_COMMENT_HELP_LINK = locale => {
  const translatedLocales = ['fr', 'es', 'it', 'ja', 'de', 'pt', 'ru'];
  const supportedLocale = translatedLocales.includes(locale) ? locale : 'en';
  return `https://support.wix.com/${supportedLocale}/article/wix-editor-providing-site-feedback`;
};

export const COMMENT_STATUS = {
  UNREAD: 'New',
  OPEN: 'Open',
  RESOLVED: 'Resolved',
  DELETED: -1,
};

export const COMMENT_STATUS_CLIENT = {
  NEW: 'Pending',
  POSTED: 'New',
  READ: 2,
  RESOLVED: 3,
  EDIT: 4,
};

export const COMMENT_COLOR = {
  A1: '#3370fb',
  A2: '#7852d2',
  A3: '#17b0e2',
  A5: '#fdb10c',
  A6: '#3d9fa1',
};

export const editorDomain = 'editor.wix.com';
export const shareYourSiteIframeUrlPostfix =
  '&isEdited=true&dsOrigin=wixwiz_feedback';
export const resolveEditorPreviewUrlForOwner = (siteId, metaSiteId) =>
  `https://editor.wix.com/html/editor/web/renderer/render/document/${siteId}?metaSiteId=${metaSiteId}`;

export const resolveEditorXPreviewUrlForOwner = (siteId, metaSiteId) =>
  `https://create.editorx.com/html/editor/web/renderer/render/document/${siteId}?metaSiteId=${metaSiteId}`;

export const OWNER_VIEW_LINK = 'https://feedback.wix.com/owner/';
export const OWNER_EDITOR_X_VIEW_LINK =
  'https://manage.editorx.com/feedback/owner/';

export const SUBMITTER_ID_KEY_DEPRECIATED = 'feedback-experts-submitterId';
export const SUBMITTER_ID_KEY = 'submitterId';
export const DATA_CAPSULE_NAMESPACE = 'feedback-experts';

export const EDITOR_TYPE = {
  EDITOR: 'EDITOR',
  EDITORX: 'EDITORX',
  ADI: 'ADI',
};

export const SITE_LOADING_GRACE = 2 * 30000;

export const BREAKPOINT_MAX_VALUE = -1;
export const BREAKPOINT_MAX_ID = 'max';
export const TPA_DESKTOP_WIDTH_THRESHOLD = 750;

export const MAX_MESSEGE_LENGTH = 1999;

export const FEEDBACK_FORM_ACTIONS = {
  OWNER_COMMENT_ACTIONS: 'commentOwnerActions',
  CLIENT_COMMENT_ACTIONS: 'commentClientActions',
  REPLY_ACTIONS: 'replyActions',
  REPLY_TIME: 'replyTime',
};
