import React, { useContext } from 'react';
import uuid from 'uuid/v4';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Card,
  Box,
  CloseButton,
  Cell,
  Button,
  Text,
} from 'wix-style-react';
import FeedbackContext from '../App/FeedbackContext';
import { ClientContext } from '../App/ClientContext';
import style from './WelcomeScreen.scss';
import logger from '../../utils/biLogger';
import { getFeedbackLightboxAction } from '@wix/bi-logger-get-feedback/v2';

function WelcomeScreen({ dismissWelcome }) {
  const { t } = useTranslation();

  const {
    user: { contactId },
  } = useContext(ClientContext);
  const { editorType } = useContext(FeedbackContext);
  return (
    <div className={style.container} data-hook="welcome-screen">
      <Card
        controls={
          <CloseButton
            dataHook="welcome-screen-close-button"
            size="medium"
            skin="dark"
            onClick={() => {
              logger.report(
                getFeedbackLightboxAction({
                  origin: 'client-side',
                  action: 'close',
                  light_box_name: 'add-your-feedback',
                  editorVersion: editorType,
                  ...(contactId && { contactId }),
                  ...(contactId
                    ? { _client_id: contactId }
                    : { _client_id: uuid() }),
                }),
              );
              dismissWelcome();
            }}
          />
        }
      >
        <Box padding="36px 36px 30px">
          <Layout cols={1} gap={6}>
            <Cell>
              <Text weight="bold" skin="standard">
                {t('get_feedback_welcome_screen_title')}
              </Text>
            </Cell>
            <Cell>
              <Text skin="standard">
                {t('get_feedback_welcome_screen_description')}
              </Text>
            </Cell>
            <Cell>
              <div style={{ paddingTop: '10px' }}>
                <Button
                  priority="secondary"
                  size="large"
                  onClick={() => {
                    logger.report(
                      getFeedbackLightboxAction({
                        origin: 'client-side',
                        action: 'got-it',
                        light_box_name: 'add-your-feedback',
                        editorVersion: editorType,
                        ...(contactId && { contactId }),
                        ...(contactId
                          ? { _client_id: contactId }
                          : { _client_id: uuid() }),
                      }),
                    );
                    dismissWelcome();
                  }}
                  dataHook="welcome-screen-got-it"
                >
                  {t('get_feedback_welcome_screen_cta')}
                </Button>
              </div>
            </Cell>
          </Layout>
        </Box>
      </Card>
    </div>
  );
}

export default WelcomeScreen;
