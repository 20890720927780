import _find from 'lodash/find';
import _get from 'lodash/get';
import * as documentAPIs from '../../../utils/documentAPI';
import { COMMENT_STATUS_CLIENT } from '../../../utils/constants';

function deactiveComments(state) {
  return state.map(comment => {
    if (comment.status === COMMENT_STATUS_CLIENT.EDIT) {
      comment = { ...comment, status: COMMENT_STATUS_CLIENT.POSTED };
    }
    if (comment.isActive) {
      return { ...comment, isActive: false };
    } else {
      return comment;
    }
  });
}

export default class CommentsReducer {
  constructor({
    viewMode,
    currentBreakPoint,
    documentServices,
    pageId,
    isSiteResponsive,
  }) {
    this.viewMode = viewMode;
    this.currentBreakPoint = currentBreakPoint;
    this.isSiteResponsive = isSiteResponsive;
    this.documentServices = documentServices;
    this.pageId = pageId;
  }

  static staleComment = {};

  reducer(state, action) {
    switch (action.type) {
      case 'SET_COMMENTS': {
        return [...action.comments];
      }
      case 'ADD_COMMENT': {
        const { x, y } = documentAPIs.getNewCommentPosition(
          this.viewMode,
          this.isSiteResponsive,
          this.currentBreakPoint,
          this.documentServices,
        );
        const staleComment =
          _find(state, { status: COMMENT_STATUS_CLIENT.NEW }) ||
          CommentsReducer.staleComment;
        const message = _get(staleComment, 'text', '');
        const name = _get(staleComment, 'name', '');
        const email = _get(staleComment, 'email', '');
        return [
          ...state.filter(comment => {
            return comment.status !== COMMENT_STATUS_CLIENT.NEW;
          }),
          {
            message,
            name,
            email,
            commentLocation: {
              x,
              y,
              pageId: this.pageId,
              breakpoint: documentAPIs.getDefaultBreakpoint({
                isSiteResponsive: this.isSiteResponsive,
                currentBreakPoint: this.currentBreakPoint,
                viewMode: this.viewMode,
              }),
            },
            status: COMMENT_STATUS_CLIENT.NEW,
            isActive: true,
            createdDate: Date.now(),
          },
        ];
      }
      case 'DELETE_COMMENT': {
        return state.filter(({ id }) => id !== action.id);
      }
      case 'DELETE_REPLY_TO_COMMENT': {
        const { commentId, replyId } = action;
        return state.map(comment => {
          if (comment.id === commentId) {
            const replies = comment.replies.filter(({ id }) => id !== replyId);
            return { ...comment, ...{ replies } };
          } else {
            return comment;
          }
        });
      }
      case 'UPDATE_COMMENT': {
        return state.map(comment => {
          if (comment.id === action.id) {
            return { ...comment, ...action.changes };
          } else {
            return comment;
          }
        });
      }
      case 'UPDATE_REPLY_TO_COMMENT': {
        const { commentId, replyId } = action;
        return state.map(comment => {
          if (comment.id === commentId) {
            comment.replies = comment.replies.map(reply => {
              if (reply.id === replyId) {
                return { ...reply, ...action.changes };
              } else {
                return reply;
              }
            });
            return comment;
          } else {
            return comment;
          }
        });
      }
      case 'OPEN_COMMENT': {
        return state.map(comment => {
          if (comment.id === action.id) {
            return { ...comment, isActive: true, keepPopupOpen: true };
          } else {
            return comment;
          }
        });
      }
      case 'DE_ACTIVATE_COMMENTS': {
        if (action.removeStale) {
          CommentsReducer.staleComment = _find(state, {
            status: COMMENT_STATUS_CLIENT.NEW,
          });
          return deactiveComments(
            state.filter(comment => {
              return comment.status !== COMMENT_STATUS_CLIENT.NEW;
            }),
          );
        } else {
          return deactiveComments(state);
        }
      }
      default:
        return state;
    }
  }
}
