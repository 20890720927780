import _filter from 'lodash/filter';
import { scriptsTopology } from '../../fromWindow';
import { PREVIEW_ID } from '../../utils/constants';

export function addStyleTagsToFrame(previewDocument) {
  const head = previewDocument.getElementsByTagName('head')[0];
  const mainAppStyle = document.createElement('link');
  mainAppStyle.type = 'text/css';
  mainAppStyle.rel = 'stylesheet';
  mainAppStyle.href = `${scriptsTopology}/feedback-app.css`;

  const appStStyleTags = _filter(document.getElementsByTagName('style'), tag =>
    tag.hasAttribute('st-id'),
  );

  const frag = document.createDocumentFragment();
  frag.appendChild(mainAppStyle);

  for (const styleTag of appStStyleTags) {
    frag.appendChild(styleTag.cloneNode(true));
  }

  head.appendChild(frag);
}

export function disableChildIframesPointerEvents(
  previewDocument = document.getElementById(PREVIEW_ID).contentDocument,
) {
  previewDocument
    .querySelectorAll('iframe')
    .forEach(frameElement => (frameElement.style.pointerEvents = 'none'));
}

export function enableChildIframesPointerEvents(
  previewDocument = document.getElementById(PREVIEW_ID).contentDocument,
) {
  previewDocument
    .querySelectorAll('iframe')
    .forEach(frameElement => (frameElement.style.pointerEvents = 'auto'));
}

export function scrollTo(x, y) {
  document.getElementById(PREVIEW_ID).contentWindow.scrollTo({
    left: x,
    top: y,
    behavior: 'smooth',
    block: 'start',
  });
}

export function getScrollOffset(
  previewFrame = document.getElementById(PREVIEW_ID),
) {
  return (
    previewFrame.contentWindow.innerWidth -
    previewFrame.contentDocument.body.offsetWidth
  );
}
