import React from 'react';
import { useTranslation } from 'react-i18next';
import { MessageModalLayout, Text } from 'wix-style-react';
import style from './DisableTool.scss';

function DisableTool() {
  const { t } = useTranslation();

  return (
    <div className={style.center}>
      <MessageModalLayout
        className={style.messageModalLayout}
        title={t('get_feedback_disable_tool_title')}
      >
        <Text>{t('get_feedback_disable_tool_body')}</Text>
        <br />
        <br />
        <br />
      </MessageModalLayout>
      <div className={style.opacityLayer}></div>
    </div>
  );
}

export default DisableTool;
