import webBiLogger from '@wix/web-bi-logger';
import { metaSiteId } from '../fromWindow';
import { getAppUrl, getAppVersion, BI_SOURCE } from '@wix/experts-common';

const logger = webBiLogger
  .factory()
  .updateDefaults({
    src: BI_SOURCE,
    msid: metaSiteId,
    appVersion: getAppVersion(getAppUrl('experts-feedback')),
  })
  .logger();

export default logger;
