import _groupBy from 'lodash/groupBy';
import _keys from 'lodash/keys';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import { COMMENT_COLOR } from './constants';

export function assignColorToComments(comments = []) {
  const BG_COLORS = _keys(COMMENT_COLOR);
  const commentsBySubmitterId = _groupBy(
    _sortBy(comments, 'createdDate'),
    'commenterId',
  );
  const colorBySubmitterId = _keys(commentsBySubmitterId).map(
    (commenterId, i) => {
      return {
        commenterId,
        color: BG_COLORS[i % BG_COLORS.length],
      };
    },
  );
  return comments.map(comment => {
    const { commenterId } = comment;
    return {
      ...comment,
      color: _get(
        _find(colorBySubmitterId, { commenterId }),
        'color',
        COMMENT_COLOR.A1,
      ),
    };
  });
}
