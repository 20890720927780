import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  lazy,
  Suspense,
} from 'react';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _includes from 'lodash/includes';
import { DocumentServicesContext } from '../App/DocumentServicesContext';
import FeedbackContext from '../App/FeedbackContext';
import { ViewModeContext } from '../App/ViewModeContext';
import { ResponsiveSiteContext } from '../App/ResponsiveSiteContext';
import { BreakpointsContext } from '../App/BreakpointsContext';

import {
  VIEW_MODE,
  VIEW_MODE_DOCUMENT_VALUES,
  DOCUMENT_SERVICES_LOADED,
  DOCUMENT_HEADER,
  PREVIEW_ID,
  DOCUMENT_CONTAINER,
  COMMENT_COLOR,
  SITE_LOADING_GRACE,
  COMMENT_STATUS,
  BREAKPOINT_MAX_VALUE,
} from '../../utils/constants';
import * as documentAPIs from '../../utils/documentAPI';
import { siteUrl } from '../../fromWindow';
import { addStyleTagsToFrame, getScrollOffset } from './frameHelpers';
import Portal from './Portal';
import feedbackPinStyle from '../FeedbackPin/FeedbackPinStyle';
import s from './SiteRenderer.scss';

const FeedbackPin = lazy(() => import('../FeedbackPin/FeedbackPin'));

function SiteRenderer({
  comments,
  pageId,
  siteDidNotRender,
  onAppendComment,
  onUpdateForStaleComment,
  onDeleteComment,
  onUpdateComment,
  onResolveComment,
  onAppendOwnerReply,
  onAppendSubmitterReply,
  onDeleteReplyToComment,
  onUpdateReplyToComment,
  onCreateContactAndAddComment,
  closeAllComments,
  onMarkCommentAsRead,
  mockDocumentServices,
  resolvedFilterOn,
  openFilterOn,
}) {
  const { documentServices, setDocumentServices } = useContext(
    DocumentServicesContext,
  );
  const { isOwner } = useContext(FeedbackContext);
  const documentServicesRef = useRef(documentServices);
  const { viewMode } = useContext(ViewModeContext);
  const { isSiteResponsive } = useContext(ResponsiveSiteContext);
  const { currentBreakPoint } = useContext(BreakpointsContext);
  const [loading, setLoading] = useState(true);
  const previewEl = useRef(null);
  const [commentTypesToShow, setCommentTypesToShow] = useState([
    COMMENT_STATUS.UNREAD,
    COMMENT_STATUS.OPEN,
  ]);

  useEffect(() => {
    if (openFilterOn) {
      setCommentTypesToShow([COMMENT_STATUS.UNREAD, COMMENT_STATUS.OPEN]);
    } else {
      setCommentTypesToShow([COMMENT_STATUS.RESOLVED]);
    }
  }, [resolvedFilterOn, openFilterOn]);

  // FOR TESTING ONLY
  useEffect(() => {
    if (!_isEmpty(mockDocumentServices)) {
      setDocumentServices(mockDocumentServices);
    }
  }, [mockDocumentServices, setDocumentServices]);

  useEffect(() => {
    function switchViewMode() {
      setLoading(true);
      previewEl.current.contentWindow.documentServices.viewMode.set(
        documentAPIs.getDocumentViewMode(viewMode),
      );
      previewEl.current.contentWindow.documentServices.waitForChangesApplied(
        () => {
          setLoading(false);
        },
      );
    }
    if (
      documentServices &&
      _get(previewEl, 'current.contentWindow.documentServices')
    ) {
      switchViewMode();
    }
  }, [documentServices, viewMode]);

  useEffect(() => {
    function handleDSLoaded({ data }) {
      if (data === DOCUMENT_SERVICES_LOADED) {
        documentServicesRef.current =
          previewEl.current.contentWindow.documentServices;
        addStyleTagsToFrame(previewEl.current.contentDocument);
        documentAPIs.setSiteActions(
          previewEl.current.contentWindow.documentServices,
        );
        previewEl.current.contentWindow.documentServices.viewMode.set(
          VIEW_MODE_DOCUMENT_VALUES.DESKTOP,
        );
        setDocumentServices(previewEl.current.contentWindow.documentServices);
        setLoading(false);
      }
    }
    window.addEventListener('message', handleDSLoaded);
    return () => {
      window.removeEventListener('message', handleDSLoaded);
    };
  }, [previewEl, setDocumentServices]);

  useEffect(() => {
    setTimeout(() => {
      if (!documentServicesRef.current) {
        setLoading(false);
        siteDidNotRender();
      }
    }, SITE_LOADING_GRACE);
  }, [documentServices, siteDidNotRender]);

  function showIfInMobile() {
    return viewMode === VIEW_MODE.MOBILE ? s.show : s.hide;
  }

  function getMobileLeft(styleOffset) {
    if (viewMode === VIEW_MODE.MOBILE) {
      const offset = styleOffset + getScrollOffset(previewEl.current) / 2;
      return {
        left: `calc(50% - ${offset}px)`,
      };
    }
  }

  function hideIfSwitchingDevice() {
    return loading ? s.hide : s.show;
  }

  function getMobileClassIfInMobile() {
    return viewMode === VIEW_MODE.MOBILE ? s.frameMobile : '';
  }

  function renderPortals() {
    const headerComments = _filter(comments, comment => {
      const { pageId, breakpoint } = comment.commentLocation;
      if (!isSiteResponsive) {
        return pageId === DOCUMENT_HEADER && breakpoint.id === viewMode;
      } else {
        const { minWidth, maxWidth } = breakpoint;
        const { max, min } = currentBreakPoint;
        if (comment.isMigrated) {
          return pageId === DOCUMENT_HEADER;
        }
        return (
          pageId === DOCUMENT_HEADER && minWidth === min && maxWidth === max
        );
      }
    });

    const currentPageComments = _filter(comments, comment => {
      const { breakpoint } = comment.commentLocation;
      if (!isSiteResponsive) {
        return (
          pageId === comment.commentLocation.pageId &&
          breakpoint.id === viewMode
        );
      } else {
        const { minWidth, maxWidth } = breakpoint;
        const { max, min } = currentBreakPoint;
        if (comment.isMigrated) {
          return pageId === comment.commentLocation.pageId;
        }
        return (
          pageId === comment.commentLocation.pageId &&
          minWidth === min &&
          maxWidth === max
        );
      }
    });

    function getPageXOffset() {
      const siteContainer = previewEl.current.contentDocument.getElementById(
        DOCUMENT_CONTAINER,
      );
      if (siteContainer) {
        return (
          (previewEl.current.contentWindow.innerWidth -
            previewEl.current.contentDocument.getElementById(DOCUMENT_CONTAINER)
              .clientWidth) /
          2
        );
      } else {
        return 0;
      }
    }

    function renderCommentPin(comment) {
      return (
        <Suspense key={comment.id} fallback={<div></div>}>
          <FeedbackPin
            key={comment.id}
            comment={comment}
            onAppendComment={onAppendComment}
            onUpdateForStaleComment={onUpdateForStaleComment}
            onDeleteComment={onDeleteComment}
            onUpdateComment={onUpdateComment}
            onResolveComment={onResolveComment}
            onAppendOwnerReply={onAppendOwnerReply}
            onAppendSubmitterReply={onAppendSubmitterReply}
            onMarkCommentAsRead={onMarkCommentAsRead}
            onDeleteReplyToComment={onDeleteReplyToComment}
            onUpdateReplyToComment={onUpdateReplyToComment}
            onCreateContactAndAddComment={onCreateContactAndAddComment}
            closeAllComments={closeAllComments}
            pageYOffset={previewEl.current.contentWindow.pageYOffset}
            pageXOffset={getPageXOffset()}
          />
        </Suspense>
      );
    }

    return (
      <>
        <Portal
          portalRoot={previewEl.current.contentDocument.getElementById(
            DOCUMENT_HEADER,
          )}
          closeAllComments={closeAllComments}
          isOwner={isOwner}
        >
          <style>{feedbackPinStyle(COMMENT_COLOR)}</style>
          {headerComments.map(comment => {
            if (isOwner) {
              if (_includes(commentTypesToShow, comment.status)) {
                return renderCommentPin(comment);
              }
            } else {
              return renderCommentPin(comment);
            }
          })}
        </Portal>
        <Portal
          portalRoot={previewEl.current.contentDocument.getElementById(pageId)}
          closeAllComments={closeAllComments}
          isOwner={isOwner}
        >
          <style>{feedbackPinStyle(COMMENT_COLOR)}</style>
          {currentPageComments.map(comment => {
            if (isOwner) {
              if (_includes(commentTypesToShow, comment.status)) {
                return renderCommentPin(comment);
              }
            } else {
              return renderCommentPin(comment);
            }
          })}
        </Portal>
      </>
    );
  }

  function renderClassicPreview() {
    return (
      <>
        <div
          style={getMobileLeft(186)}
          className={`${s.mobile} ${showIfInMobile()}`}
        ></div>
        <div
          style={getMobileLeft(197)}
          className={`${s.mobileBg} ${showIfInMobile()}`}
        ></div>
        <iframe
          id={PREVIEW_ID}
          ref={previewEl}
          className={`${
            s.editorIframe
          } ${getMobileClassIfInMobile()} ${hideIfSwitchingDevice()}`}
          src={siteUrl}
          data-hook="site-renderer-preview"
        ></iframe>
        <div
          style={getMobileLeft(197)}
          className={`${s.mobileFooter} ${showIfInMobile()}`}
        ></div>
        {previewEl.current && documentServices && comments.length > 0
          ? renderPortals()
          : ''}
      </>
    );
  }

  function renderResponsivePreview() {
    const width =
      currentBreakPoint.max === BREAKPOINT_MAX_VALUE
        ? '100%'
        : documentAPIs.calcBreakpointWidth({ ...currentBreakPoint });
    return (
      <div style={{ margin: '0 auto', display: 'grid', width }}>
        <iframe
          id={PREVIEW_ID}
          ref={previewEl}
          style={{ width }}
          className={`${
            s.editorIframe
          } ${getMobileClassIfInMobile()} ${hideIfSwitchingDevice()}`}
          src={siteUrl}
          data-hook="site-renderer-preview"
        ></iframe>
        {previewEl.current && documentServices && comments.length > 0
          ? renderPortals()
          : ''}
      </div>
    );
  }

  return (
    <div className={s.siteRenderer} data-hook="site-renderer">
      <div className={s.iframeWrapper}>
        <div className={s.iframeContainer}>
          {isSiteResponsive
            ? renderResponsivePreview()
            : renderClassicPreview()}
        </div>
      </div>
    </div>
  );
}

export default SiteRenderer;
