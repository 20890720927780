import _invoke from 'lodash/invoke';
import _head from 'lodash/head';
import _isNull from 'lodash/isNull';
import _some from 'lodash/some';
import {
  VIEW_MODE,
  VIEW_MODE_DOCUMENT_VALUES,
  DOCUMENT_HEADER,
  PREVIEW_ID,
  DOCUMENT_MASTER_PAGE,
  BREAKPOINT_MAX_VALUE,
} from './constants';

export const getHeaderDocumentComponent = (viewMode, documentServices) => {
  return (
    _invoke(documentServices, 'components.layout.get', {
      id: DOCUMENT_HEADER,
      type: getDocumentViewMode(viewMode),
    }) || {
      height: 200,
    }
  );
};

export const getDocumentViewMode = viewMode => {
  return viewMode === VIEW_MODE.DESKTOP
    ? VIEW_MODE_DOCUMENT_VALUES.DESKTOP
    : VIEW_MODE_DOCUMENT_VALUES.MOBILE;
};

export const getNewCommentPosition = (
  viewMode,
  isSiteResponsive,
  currentBreakPoint,
  documentServices,
) => {
  const siteHeader = getHeaderDocumentComponent(viewMode, documentServices);
  if (viewMode === VIEW_MODE.DESKTOP) {
    const HEADER_HEIGHT = 56;
    let centerX;
    if (isSiteResponsive && currentBreakPoint.max !== BREAKPOINT_MAX_VALUE) {
      centerX =
        window.innerWidth / 2 - (window.innerWidth - currentBreakPoint.max) / 2;
    } else {
      centerX = window.innerWidth / 2;
    }
    const centerY =
      document.getElementById(PREVIEW_ID).contentWindow.pageYOffset +
      document.documentElement.clientHeight / 2 -
      (siteHeader.height / 2 + HEADER_HEIGHT);
    return {
      x: centerX,
      y: centerY,
    };
  } else {
    const HEADER_HEIGHT = 65;
    const centerX = 145;
    const centerY =
      document.getElementById(PREVIEW_ID).contentWindow.pageYOffset +
      document.documentElement.clientHeight / 2 -
      (siteHeader.height / 2 + HEADER_HEIGHT);
    return {
      x: centerX,
      y: centerY,
    };
  }
};

const checkForComponentPage = ({
  documentComponents,
  documentServices,
  comment,
}) => {
  const comp = documentServices.components.getPage(_head(documentComponents));
  if (_isNull(comp)) {
    return comment.commentLocation.pageId;
  }
  if (comp.id === DOCUMENT_MASTER_PAGE) {
    if (_some(documentComponents, { id: DOCUMENT_HEADER })) {
      return DOCUMENT_HEADER;
    } else {
      return comment.commentLocation.pageId;
    }
  }
  return comp.id;
};

export const getComponentDataFromDocument = ({
  isSiteResponsive,
  documentServices,
  viewMode,
  x,
  y,
  pageXOffset,
  clientY,
  comment,
}) => {
  if (isSiteResponsive) {
    const documentComponents = documentServices.components.get.getComponentsAtXYConsideringFrame(
      x + pageXOffset,
      clientY,
    );
    const containerPageId = checkForComponentPage({
      documentComponents,
      documentServices,
      comment,
    });
    const comp = _head(documentComponents) || {};
    const yPosition = clientY - 15;
    if (
      comment.pageid === DOCUMENT_HEADER &&
      containerPageId !== DOCUMENT_HEADER
    ) {
      y =
        yPosition -
        getHeaderDocumentComponent(viewMode, documentServices).height;
    }
    if (
      comment.pageid !== DOCUMENT_HEADER &&
      containerPageId === DOCUMENT_HEADER
    ) {
      y = yPosition;
    }
    return {
      compId: comp.id,
      containerPageId,
      updatedY: y,
    };
  } else {
    const documentComponents = documentServices.components.get.byXYRelativeToScreen(
      x + pageXOffset,
      clientY,
    );
    const containerPageId = checkForComponentPage({
      documentComponents,
      documentServices,
      comment,
    });
    const comp = _head(documentComponents) || {};
    const yPosition = clientY - 15;
    if (
      comment.pageid === DOCUMENT_HEADER &&
      containerPageId !== DOCUMENT_HEADER
    ) {
      y =
        yPosition -
        getHeaderDocumentComponent(viewMode, documentServices).height;
    }
    if (
      comment.pageid !== DOCUMENT_HEADER &&
      containerPageId === DOCUMENT_HEADER
    ) {
      y = yPosition;
    }
    return {
      compId: comp.id,
      containerPageId,
      updatedY: y,
    };
  }
};

export const getDefaultBreakpoint = ({
  isSiteResponsive,
  currentBreakPoint,
  viewMode,
}) => {
  return {
    id: isSiteResponsive ? currentBreakPoint.id : viewMode,
    maxWidth: currentBreakPoint.max,
    minWidth: currentBreakPoint.min,
  };
};

export const calcBreakpointWidth = ({ max, min }) => {
  const val = min + (max - min) * 0.2;
  return Math.ceil(val / 10) * 10;
};

export const setSiteActions = documentServices => {
  documentServices.documentMode.enablePlaying(true);
  documentServices.documentMode.enableZoom(true);
  documentServices.documentMode.enableSocialInteraction(false);
  documentServices.documentMode.enableExternalNavigation(false);
  documentServices.documentMode.enableSlideShowGalleryClick(true);
  documentServices.documentMode.enableTinyMenuOpening(true);
  documentServices.documentMode.enableRenderFixedPositionContainers(true);
  documentServices.documentMode.enableRenderFixedPositionBackgrounds(true);
  documentServices.documentMode.enableBackToTopButton(true);
  documentServices.documentMode.enablePageProtection(false);
  documentServices.documentMode.enableSiteMembersDialogsOpening(true);
  documentServices.documentMode.enableResetGalleryToOriginalState(true);
  documentServices.documentMode.enableResetComponent(false);
  documentServices.documentMode.resetBehaviors(true);
  documentServices.documentMode.enableAction('screenIn', true);
  documentServices.documentMode.enableAction('viewportEnter', true);
  documentServices.documentMode.enableAction('viewportLeave', true);
  documentServices.documentMode.enableAction('load', true);
  documentServices.documentMode.enableAction('scrollScrub', true);
  documentServices.documentMode.enableAction('exit', true);
  documentServices.documentMode.enableAction('bgScrub', true);
  documentServices.documentMode.enableShouldUpdateJsonFromMeasureMap(false);
  documentServices.documentMode.allowSiteOverflow(true);
  documentServices.documentMode.setComponentViewMode('preview');
  documentServices.documentMode.allowWixCodeInitialization(true);
  documentServices.documentMode.showHiddenComponents(false);
  documentServices.documentMode.showControllers(false);
  documentServices.actions.executeAnimationsInPage();
};
