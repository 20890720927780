import { createPortal } from 'react-dom';
import { useState, useEffect } from 'react';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

export default function Portal({
  children,
  portalRoot,
  closeAllComments,
  isOwner,
}) {
  const [containerEl] = useState(document.createElement('div'));
  const [root, setRoot] = useState(null);

  useEffect(() => {
    function closeAllCommentsForDoc(e) {
      const path = _get(e, 'path') || (e.composedPath && e.composedPath());
      if (path) {
        const clickCameFromFeebackElement = path.find(elm =>
          _includes(_get(elm, 'attributes.class.value', ''), 'feedback'),
        );
        if (!clickCameFromFeebackElement || isOwner) {
          closeAllComments({ removeStale: true });
        }
      }
    }
    if (portalRoot && portalRoot !== root) {
      containerEl.style = 'position: absolute;';
      portalRoot.appendChild(containerEl);
      setRoot(portalRoot);
      portalRoot.addEventListener('click', closeAllCommentsForDoc);
    }
  }, [closeAllComments, containerEl, portalRoot, root, isOwner]);

  if (!root) {
    return null;
  }
  return createPortal(children, containerEl);
}
