import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TextButton, Box, DropdownBase, Input, Divider } from 'wix-style-react';
import CommentItem from './CommentItem';
import s from './CommentsList.scss';
import { COMMENT_STATUS } from '../../utils/constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Duplicate from 'wix-ui-icons-common/Duplicate';
import StatusComplete from 'wix-ui-icons-common/StatusComplete';
import FeedbackContext from '../App/FeedbackContext';
import Dismiss from 'wix-ui-icons-common/Dismiss';
import logger from '../../utils/biLogger';
import {
  getFeedbackClickedCopyLink,
  getFeedbackClickOnOptionDropdownMenu,
  getFeedbackClickOnDropdownMenu,
  getFeedbackViewComment,
} from '@wix/bi-logger-get-feedback/v2';

const CommentsList = ({
  isShown,
  onOpenComment,
  onSetFilterOpenComments,
  onSetFilterResolvedComments,
  resolvedFilterOn,
  openFilterOn,
  setShouldShowCommentsList,
  comments = [],
}) => {
  const { t } = useTranslation();
  const { shareUrl, editorType, role } = useContext(FeedbackContext);
  const [filteredComments, setFilteredComments] = useState([]);
  const [copySuccess, setCopySuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [open, setOpen] = useState(false);

  const options = [
    {
      id: 0,
      value: t('get-feedback-filters-show-open-comments'),
      label: t('get_feedback_comments_list_show_open_comments'),
    },
    {
      id: 1,
      value: t('get-feedback-filters-show-resolved-comments'),
      label: t('get_feedback_comments_list_show_resolved_comments'),
    },
  ];

  useEffect(() => {
    if (openFilterOn) {
      setFilteredComments(
        comments.filter(
          ({ status }) =>
            status === COMMENT_STATUS.OPEN || status === COMMENT_STATUS.UNREAD,
        ),
      );
    } else if (resolvedFilterOn) {
      setFilteredComments(
        comments.filter(({ status }) => status === COMMENT_STATUS.RESOLVED),
      );
    } else {
      setFilteredComments([]);
    }
  }, [comments, openFilterOn, resolvedFilterOn]);

  function handleCopyClicked() {
    logger.report(
      getFeedbackClickedCopyLink({
        origin: 'comments',
        editorVersion: editorType,
        user_type: role,
      }),
    );

    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 1000);
  }

  function handleFilterOnSelect(option) {
    setSelectedOption(option.id);
    setOpen(false);
    if (option.id === 0) {
      logger.report(
        getFeedbackClickOnOptionDropdownMenu({
          origin: 'view-comment-page',
          editorVersion: editorType,
          user_type: role,
          option: 'open_comments',
        }),
      );
      onSetFilterOpenComments(true);
      onSetFilterResolvedComments(false);
    } else {
      logger.report(
        getFeedbackClickOnOptionDropdownMenu({
          origin: 'view-comment-page',
          editorVersion: editorType,
          user_type: role,
          option: 'resolved_comments',
        }),
      );

      onSetFilterResolvedComments(true);
      onSetFilterOpenComments(false);
    }
  }

  function renderFilters() {
    return (
      <div className={s.listHeader}>
        <Box align="right" marginBottom={2} marginTop={1}>
          <TextButton
            size="tiny"
            suffixIcon={<Dismiss size="12" />}
            onClick={() => setShouldShowCommentsList(false)}
            style={{ fontWeight: 'bold' }}
            dataHook="close-comment-list-btn"
          >
            {t('get_feedback_share_lb_close_cta')}
          </TextButton>
        </Box>
        <DropdownBase
          style={{ width: '100%' }}
          dataHook="drop-down-base"
          open={open}
          onClickOutside={() => setOpen(false)}
          options={options}
          selectedId={selectedOption}
          onSelect={option => handleFilterOnSelect(option)}
        >
          <Input
            menuArrow
            dataHook="drop-down-opener"
            value={options[selectedOption].label}
            disableEditing
            onInputClicked={() => {
              logger.report(
                getFeedbackClickOnDropdownMenu({
                  origin: 'view-comment-page',
                  editorVersion: editorType,
                  user_type: role,
                }),
              );
              setOpen(true);
            }}
          />
        </DropdownBase>
      </div>
    );
  }

  function renderCopyToClipboard() {
    return (
      <div>
        <Divider />
        <Box height="60px" bottom="0" width="100%">
          <Box marginLeft={5} verticalAlign="middle">
            <CopyToClipboard text={shareUrl}>
              <TextButton
                prefixIcon={<Duplicate size="24" />}
                weight="normal"
                size="small"
                onClick={handleCopyClicked}
                dataHook="copy-link"
              >
                {t('get_feedback_comments_list_copy_shareable_link')}
              </TextButton>
            </CopyToClipboard>
          </Box>
        </Box>
        <div className={s.props}>
          <div className={`${s.helper} ${copySuccess ? s.show : ''}`}>
            <Box
              bottom="72px"
              position="fixed"
              backgroundColor="D10"
              padding={1}
              marginLeft={5}
              borderRadius={6}
            >
              <TextButton
                prefixIcon={<StatusComplete size="24" />}
                skin="light"
                light
                size="tiny"
                weight="normal"
                disabled={true}
              >
                {t('get_feedback_share_lb_copy_helper')}
              </TextButton>
            </Box>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${s.root} ${isShown ? s.shown : ''}`}
      data-hook="comments-list-wrapper"
    >
      {renderFilters()}
      <div className={`${s.commentsWrapper}`}>
        {filteredComments.map(comment => (
          <CommentItem
            key={comment.id}
            comment={comment}
            onOpenComment={comment => {
              logger.report(
                getFeedbackViewComment({
                  origin: 'list-view',
                  editorVersion: editorType,
                  comment_id: comment.id,
                  commentStatus: comment.status,
                  breakPoint: JSON.stringify(
                    comment.commentLocation.breakpoint,
                  ),
                }),
              );
              onOpenComment(comment);
            }}
          />
        ))}
      </div>
      {renderCopyToClipboard()}
    </div>
  );
};

export default CommentsList;
