import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/browser';
import i18n from './i18n';
import FeedbackContext from './components/App/FeedbackContext';
import {
  locale,
  userGuid,
  isOwner,
  isPartner,
  shareUrl,
  editorType,
  role,
  commentId,
  error,
  comments as savedComments,
} from './fromWindow';
import App from './components/App/App';

Sentry.configureScope(scope => {
  scope.setUser({ id: userGuid });
  scope.setTag('Experts App', 'Get Feedback');
});

const feedbackProps = {
  locale,
  userGuid,
  isOwner,
  isPartner,
  shareUrl,
  role,
  savedComments,
  commentId,
  editorType,
  error,
};

ReactDOM.render(
  <Suspense fallback="">
    <I18nextProvider i18n={i18n(locale)}>
      <FeedbackContext.Provider value={feedbackProps}>
        <App />
      </FeedbackContext.Provider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root'),
);
