import React from 'react';
import { Loader } from 'wix-style-react';
import style from './LoadingScreen.scss';

export default function LoadingScreen({ showLoader = true }) {
  return (
    <div data-hook="feedback-loader">
      {showLoader ? (
        <div className={style.center}>
          <Loader size="medium"></Loader>
        </div>
      ) : (
        ''
      )}
      <div className={style.opacityLayer}></div>
    </div>
  );
}
