const style = COLORS => `
html {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.feedbackIconDraggableWrapper {
    position: relative;
    z-index: 1100;
}
.feedbackIcon {
    scale: 1.0;
    border-radius: 50%;
    border-color: #fff;
    width: 30px;
    height: 30px;
    padding: 7px;
    border-width: 2px;
    box-shadow: 0 0 6px 0 rgba(100, 59, 1, 0.38);
    border-style: solid;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
}

.feedbackIcon:active:hover {
    cursor: move;
}

.owner.feedbackIcon:active:hover {
    cursor: default;
}

.feedbackIcon:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.feedbackIcon.posted, .feedbackIcon.edit {
    background-color: #cf00e8;
}

.feedbackIcon.new {
    background-color: #cf00e8;
}

.feedbackIcon.resolved {
    background-color: #3182c8;    
}

.feedbackIcon.A1 {
    background-color: ${COLORS.A1}
}

.feedbackIcon.A2 {
    background-color: ${COLORS.A2}
}

.feedbackIcon.A3 {
    background-color: ${COLORS.A3}
}

.feedbackIcon.A4 {
    background-color: ${COLORS.A4}
}

.feedbackIcon.A5 {
    background-color: ${COLORS.A5}
}

.feedbackIcon.A6 {
    background-color: ${COLORS.A6}
}

.feedbackIconCenter {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%
}

.feedbackPinV {
    width: 12px;
    height: 12px;
}
`;

export default style;
