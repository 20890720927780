import React, { useContext, useState, useEffect } from 'react';
import uuid from 'uuid/v4';
import _isBoolean from 'lodash/isBoolean';
import { useTranslation } from 'react-i18next';
import { TextButton, Button, CounterBadge, Text } from 'wix-style-react';
import Desktop from 'wix-ui-icons-common/Desktop';
import Mobile from 'wix-ui-icons-common/Mobile';
import ChatFilled from 'wix-ui-icons-common/ChatFilled';
import Chat from 'wix-ui-icons-common/Chat';
import { accountLogo } from '../../fromWindow';
import { ViewModeContext } from '../App/ViewModeContext';
import { ResponsiveSiteContext } from '../App/ResponsiveSiteContext';
import { DocumentServicesContext } from '../App/DocumentServicesContext';
import { BreakpointsContext } from '../App/BreakpointsContext';
import FeedbackContext from '../App/FeedbackContext';
import { ClientContext } from '../App/ClientContext';
import Add from 'wix-ui-icons-common/Add';
import { ReactComponent as Logo } from '../../assets/images/wix-logo.svg';
import { ReactComponent as EditorXLogo } from '../../assets/images/editor-x-logo.svg';
import CommentsList from '../CommentsList/CommentsList';
import PopoverHelp from './PopoverHelp/PopoverHelp';
import { COMMENT_STATUS, VIEW_MODE } from '../../utils/constants';
import { getDefaultBreakpoint } from '../../utils/documentAPI';
import Breakpoints from './Breakpoints';
import s from './Header.scss';
import logger from '../../utils/biLogger';
import {
  getFeedbackChooseMode,
  getFeedbackHideOrShowCommentList,
  getFeedbackAddACommentButton,
} from '@wix/bi-logger-get-feedback/v2';

const unreadCount = comments =>
  comments.filter(c => c.status === COMMENT_STATUS.UNREAD).length;

const Header = ({
  comments,
  onDesktopClick,
  onMobileClick,
  onAddComment,
  onHelpButtonClick,
  onOpenComment,
  closeAllComments,
  onSetFilterOpenComments,
  onSetFilterResolvedComments,
  resolvedFilterOn,
  openFilterOn,
  isLoadingSite,
}) => {
  const { t } = useTranslation();

  const { isOwner, isPartner, editorType, role } = useContext(FeedbackContext);
  const {
    user: { contactId },
  } = useContext(ClientContext);
  const { viewMode, setViewMode } = useContext(ViewModeContext);
  const { isSiteResponsive } = useContext(ResponsiveSiteContext);
  const { documentServices } = useContext(DocumentServicesContext);
  const { currentBreakPoint } = useContext(BreakpointsContext);
  const [shouldShowCommentsList, setShouldShowCommentsList] = useState(true);

  useEffect(() => {
    if (siteLoadedAndShouldShowCommentsList()) {
      setShouldShowCommentsList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSite]);

  function siteLoadedAndShouldShowCommentsList() {
    return (
      _isBoolean(isLoadingSite) && !isLoadingSite && shouldShowCommentsList
    );
  }
  function getTabClassName(type) {
    return type === viewMode ? s.active : '';
  }

  function renderViewMode() {
    return (
      <ul className={s.tabs}>
        <li className={getTabClassName(VIEW_MODE.DESKTOP)}>
          <TextButton
            dataHook="desktop-button"
            skin="dark"
            prefixIcon={<Desktop />}
            onClick={() => {
              logger.report(
                getFeedbackChooseMode({
                  editorVersion: editorType,
                  mode: VIEW_MODE.DESKTOP,
                  ...(contactId
                    ? { _client_id: contactId }
                    : { _client_id: uuid() }),
                }),
              );
              setViewMode(VIEW_MODE.DESKTOP);
              onDesktopClick();
            }}
          >
            {t('get_feedback_header_view_type_button_desktop')}
          </TextButton>
        </li>
        <li className={getTabClassName(VIEW_MODE.MOBILE)}>
          <TextButton
            dataHook="mobile-button"
            skin="dark"
            prefixIcon={<Mobile />}
            onClick={() => {
              logger.report(
                getFeedbackChooseMode({
                  editorVersion: editorType,
                  mode: VIEW_MODE.DESKTOP,
                  ...(contactId
                    ? { _client_id: contactId }
                    : { _client_id: uuid() }),
                }),
              );
              setViewMode(VIEW_MODE.MOBILE);
              onMobileClick();
            }}
          >
            {t('get_feedback_header_view_type_button_mobile')}
          </TextButton>
        </li>
      </ul>
    );
  }

  function renderHeader() {
    if (_isBoolean(isLoadingSite) && !isLoadingSite) {
      if (isSiteResponsive) {
        const breakpoints = documentServices.breakpoints.get(
          documentServices.pages.getCurrentPage(),
        );
        return <Breakpoints breakpoints={breakpoints} />;
      } else {
        return renderViewMode();
      }
    }
  }

  function renderHelp() {
    return isOwner ? (
      <>
        <div className={s.separator}></div>
        <TextButton
          dataHook="help-button"
          skin="dark"
          onClick={onHelpButtonClick}
        >
          {t('get_feedback_header_help_button')}
        </TextButton>
      </>
    ) : null;
  }

  function renderPartnerOrWixLogo() {
    if (isPartner && accountLogo) {
      return <img height="30px" src={accountLogo} />;
    }
    if (isPartner) {
      return (
        <Text dataHook="header-pro" className={s.pro} weight="bold">
          {t('get_feedback_header_preview_mode')}
        </Text>
      );
    }
    return isSiteResponsive ? (
      <EditorXLogo data-hook="header-editor-x-logo" />
    ) : (
      <Logo data-hook="header-logo" />
    );
  }
  return (
    <div
      className={s.root}
      onClick={() => {
        closeAllComments({ removeStale: true });
      }}
    >
      <div className={s.logoSection}>
        {renderPartnerOrWixLogo()}
        {renderHelp()}
      </div>
      <div className={s.viewType}>{renderHeader()}</div>
      <div className={s.right}>
        {isOwner ? (
          <div data-hook="owner-panel">
            <span
              style={{ position: 'relative', cursor: 'pointer' }}
              onClick={() => {
                logger.report(
                  getFeedbackHideOrShowCommentList({
                    origin: 'view-comment-page',
                    user_type: role,
                    editorVersion: editorType,
                    action: shouldShowCommentsList
                      ? 'hide-comments'
                      : 'show-comments',
                  }),
                );
                setShouldShowCommentsList(!shouldShowCommentsList);
              }}
              data-hook="toggle-comments-button"
            >
              {unreadCount(comments) > 0 ? (
                <>
                  <ChatFilled />
                  <div
                    style={{
                      position: 'absolute',
                      top: '-15px',
                      left: '10px',
                      pointerEvents: 'none',
                    }}
                  >
                    <CounterBadge dataHook="unread-count-badge" skin="danger">
                      {unreadCount(comments)}
                    </CounterBadge>
                  </div>
                </>
              ) : (
                <>
                  <Chat />
                </>
              )}
            </span>
            {!isLoadingSite ? (
              <CommentsList
                comments={comments}
                onSetFilterOpenComments={toggle =>
                  onSetFilterOpenComments(toggle)
                }
                onSetFilterResolvedComments={toggle =>
                  onSetFilterResolvedComments(toggle)
                }
                resolvedFilterOn={resolvedFilterOn}
                openFilterOn={openFilterOn}
                setShouldShowCommentsList={toggle =>
                  setShouldShowCommentsList(toggle)
                }
                isShown={siteLoadedAndShouldShowCommentsList()}
                onOpenComment={comment => {
                  onOpenComment(comment);
                  setShouldShowCommentsList(false);
                }}
              />
            ) : null}
          </div>
        ) : (
          <>
            <div style={{ marginRight: '24px' }}>
              <PopoverHelp />
            </div>
            <Button
              dataHook="add-comment-button"
              size="small"
              prefixIcon={<Add />}
              onClick={e => {
                e.stopPropagation();
                logger.report(
                  getFeedbackAddACommentButton({
                    origin: 'client-side',
                    editorVersion: editorType,
                    breakpoint: JSON.stringify(
                      getDefaultBreakpoint({
                        isSiteResponsive,
                        currentBreakPoint,
                        viewMode,
                      }),
                    ),
                    ...(contactId && { contactId }),
                    ...(contactId
                      ? { _client_id: contactId }
                      : { _client_id: uuid() }),
                  }),
                );
                onAddComment();
              }}
            >
              {t('get_feedback_header_add_comments_button')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
